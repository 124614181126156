<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-text-field
          v-model="form.sort"
          label="Sort order (asc)"
          outlined
          class="mt-5"
        />

        <v-autocomplete
          v-model="form.selectable_id"
          placeholder="Select an item"
          :items="options"
          item-text="title"
          item-value="value"
          label="Item"
        ></v-autocomplete>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { between, required } from '@core/utils/validation';
import { } from '@mdi/js';
import { ref } from '@vue/composition-api';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const field = (name, initValue) => ({ [name]: props.resource ? props.resource[name] : initValue })

    const initialForm = {
      ...field('sort', 999),
      ...field('selectable_id', null),
    }

    const form = ref({ ...initialForm })
    const formElem = ref(null)

    const validate = () => {
      if (!formElem.value.validate()) return
      const data = { ...form.value }
      emit('submit', data)
    }

    return {
      form,
      formElem,
      validate,
      required,
      between,
    }
  },
}
</script>
